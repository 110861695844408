import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Card } from "react-bootstrap";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { transformCloudinaryURL, availabilityToLocalizedString } from "../util";

const Hero = ({ backgroundImg }) => {
  const { t } = useTranslation();

  return (
    <figure
      style={{
        width: "100%",
        minHeight: "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
      }}
      className="shadow"
    >
      <div
        style={{
          width: "fit-content",
          padding: "20px 40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: 2,
          color: "black",
          backgroundColor: "rgba(255,255,255,0.8)",
          position: "relative",
        }}
      >
        <h1
          style={{ width: "fit-content", textAlign: "center" }}
          className="logo"
        >
          {t("logoTitle")}
        </h1>
        <h3
          style={{ width: "fit-content", textAlign: "center" }}
          className="color-secondary"
        >
          {t("heroHeading")}
        </h3>
        <h4 style={{ width: "fit-content", textAlign: "center" }}>
          {t("heroSubheading")}
        </h4>
      </div>
    </figure>
  );
};

const Properties = ({ properties, rooms }) => {
  const { t, i18n } = useTranslation();

  // function findPropertyFirstAvailability(p) {
  //   const ownRooms = rooms.filter(r => r.frontmatter.parent === p.frontmatter.title)
  //   const ownRoomsAvailabilities = ownRooms.map(r => new Date(r.frontmatter.available_from))

  //   const bySoonestDate = (soonest, date) => {
  //     if (!soonest || date < soonest) {
  //       return date
  //     }else{
  //       return soonest
  //     }
  //   }

  //   return ownRoomsAvailabilities.reduce(bySoonestDate)
  // }

  // const availabilityToString = availabilityToLocalizedString(t, i18n.language)

  return (
    <section className="grid-container-responsive">
      {properties.map((p) => (
        <Link to={p.fields.slug} style={{color: "unset", textDecoration: "none"}}>
          <Card key={p.fields.slug}>
            <div className="aspect-ratio-box r-1600-1067">
              <img
                src={transformCloudinaryURL(p.frontmatter.photo_main, {
                  w: 400,
                })}
                alt="property"
                style={{
                  width: `100%`,
                }}
                className="aspect-ratio-box-inside"
              />
            </div>
            <Card.Body>
              <Card.Title
                className="color-secondary"
                style={{ textAlign: "center" }}
              >
                {p.frontmatter.title}
              </Card.Title>
              <Card.Text style={{ textAlign: "center" }}>
                {p.frontmatter[`phrase_${i18n.language}`]}
              </Card.Text>
              <span
                style={{
                  width: "100%",
                  display: "block",
                  textAlign: "center",
                  color: "blue"
                }}
              >
                {t("seeProperty")}
              </span>
            </Card.Body>
            {/* <Card.Footer className="card-footer">
              <b className='text-primary' style={{ fontSize: '1.3em' }} >
                {
                  availabilityToString(
                    findPropertyFirstAvailability(p)
                  )
                }
              </b>
            </Card.Footer> */}
          </Card>
        </Link>
      ))}
    </section>
  );
};

const IndexPage = ({ data }) => {
  const { t } = useTranslation();
  const rooms = data.rooms.nodes;
  const properties = data.properties.nodes;
  const backgroundImg = data.backgroundImg.childImageSharp.fixed.src;
  const siteUrl = data.site.siteMetadata.siteUrl;

  return (
    <Layout>
      <Helmet>
        <link rel="alternate" hreflang="en" href={siteUrl + ""} />
        <link rel="alternate" hreflang="pt" href={siteUrl + "/pt"} />
      </Helmet>
      <Hero backgroundImg={backgroundImg}></Hero>
      <main>
        <section className="section-banner">
          <h2> {t("properties")} </h2>
        </section>
        <Properties properties={properties} rooms={rooms}></Properties>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    properties: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "property" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          photo_main
          phrase_en
          phrase_pt
        }
        fields {
          slug
        }
      }
    }
    rooms: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "room" } } }
    ) {
      nodes {
        id
        frontmatter {
          parent
          available_from
        }
      }
    }
    backgroundImg: file(
      relativePath: { eq: "background/filipp-nekhaev-Ycnt8BKlMtY-unsplash.jpg" }
    ) {
      childImageSharp {
        fixed(width: 1600, height: 1024) {
          src
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default IndexPage;
